// Import base Bootstrap functions, variables, and mixins
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.root {
    text-shadow: none;
}

.header {
    cursor: pointer;

    &:hover {
        color: $purple;
    }
}
